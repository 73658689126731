<template>
    <el-container  class="jumpInvest">
        <el-header>
            <div class="page_title1">
                找项目：科技企业集聚追踪系统（精准招商）
            </div>
            <div class="page_title2">
                电子政策汇编系统-{{policy_proName?policy_proName:''}}潜在投资方
            </div>

        </el-header>
        <el-main>
            <el-button  class="go_back" type="text" @click="goBack"> <i class="el-icon-arrow-left"></i>返回</el-button>
            <el-table border :data="tableData" style="width: 100%;">
                <el-table-column label="姓名" align="center" prop='investor_name' min-width="60" show-overflow-tooltip></el-table-column>
                <el-table-column label="公司名称" align="center" prop='investor_company' min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="品牌名称" align="center" prop='investor_brand' min-width="100" show-overflow-tooltip></el-table-column>
                <el-table-column label="联系方式" align="center" prop='investor_mobile' min-width="140" show-overflow-tooltip></el-table-column>
                <el-table-column label="邮箱" align="center" prop='investor_email' min-width="140" show-overflow-tooltip></el-table-column>
                <el-table-column label="投资领域" align="center"  min-width="100" show-overflow-tooltip>>
                    <template slot-scope="scope">
                        <span style="margin:3px;" v-for="(item,index) in scope.row.morph_industry" :key="index">{{item.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="投资轮次" align="center"  min-width="100" show-overflow-tooltip>>
                    <template slot-scope="scope">
                        <span style="margin:3px;" v-for="(item,index) in scope.row.morph_stage" :key="index">{{item.title}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="投资金额" align="center" prop='data_total_money' min-width="140" show-overflow-tooltip></el-table-column>
                <el-table-column label="具体描述" align="investor_desc" prop='title' min-width="140" show-overflow-tooltip></el-table-column>
                <el-table-column label="匹配度" align="center" prop='match_weight' min-width="140" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span  style="color: #409EFF;">{{scope.row.match_weight?scope.row.match_weight:0}}%</span>
                    </template>
                </el-table-column>
            </el-table>
            <Pages  @currentPageChange="pageChange"
                    :pageSize="per_page"
                    :page="meta.current_page"
                    :total="meta.total"
                    v-if="tableData.length>0"
                    style="margin:20px auto;text-align: center;"
            />
        </el-main>
    </el-container>
</template>

<script>
    import Pages from "@/components/PageComponents"; //分页
    import {
        success,
        error,
        warning
    } from "@/utils/notification";
    export default {
       data(){
           return{
               meta:{},
               page:1,
               tableData:[
               ],
               per_page:15,
               policy_proName:'',
               project_id:0,
           }
       },
        components:{
            Pages
        },
        created() {
            this.policy_proName = sessionStorage.policy_proName
            if(this.$route.query.id){
                this.get_investlist(this.$route.query.id)
                this.project_id = this.$route.query.id
                // this.get_investlist(9181)
            }
        },
        methods: {
            pageChange(val) {
                this.page = val
                this.get_investlist(this.project_id)
            },
            goBack(){
                this.$router.go(-1)
                sessionStorage.backName = 'showPro'
            },
            get_investlist(id){ // 项目下的invest 列表
               this.$http.get(this.URL.adminUrl.policy_pro_investlist+id,{params:{limit:15,page:this.page}})
                .then(res=>{
                    this.tableData = res.data.data.data
                    this.meta = res.data.data.meta
                })
                .catch(err=>{
                    error('请求数据失败，请联系管理员')
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped>
    .go_back {
        height: 40px !important;
    }
    .page_title1{
        text-align: center;
        font-size: 28px;
        font-width: bold;
        color:#333;
    }
    .page_title2{
        margin-top:20px;
        text-align: center;
        font-size: 24px;
        font-width: 500;
    }
    .el-tag {
        margin:3px;
    }
</style>